import React from 'react'
import GlobalStyles from './styles/GlobalStyles'
import { Provider } from 'jotai'
import OneSignal from 'react-onesignal'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import Routes from './routes'

require('dotenv').config()

Bugsnag.start({
  apiKey: '0fbe729cbd6733e7c921ce2c74c32d4f',
  plugins: [new BugsnagPluginReact()]
})

function App() {
  OneSignal.initialize(process.env.REACT_APP_ONESIGNAL_ID, {
    allowLocalhostAsSecureOrigin: true,
  })

  const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

  return (
    <ErrorBoundary>
      <Provider>
        <GlobalStyles />
        <Routes />
      </Provider>
    </ErrorBoundary>
  )
}

export default App

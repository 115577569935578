import React from 'react'
import styled from 'styled-components'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'
import media from '../../styles/media'

import { Container, Row, Col } from 'reactstrap'
import SEO from '../../components/common/Seo'
import AboutContentContainer from '../../components/home/AboutContentContainer'
import BuilderDonationPane from '../../components/home/BuilderDonationPane'
import DonationAndRewardsPane from '../../components/home/DonationAndRewardsPane'

const { colors } = theme

const Main = styled.main`
  ${media.tablet`
    padding-top: 48px;
  `}
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  font-size: 18px;
  font-weight: 700;
  color: ${colors.primary};
`

const StyledGrid = styled(Container)`
  ${mixins.mainPageGrid}

  .builder-side-panel {
    margin-top: 120px;
    background-color: ${colors.light};
    padding: 30px;
    border: 2px solid ${colors.lightShade};

    ${media.tablet`
      margin: 0;
    `}
  }
  .sidebar-description {
    font-size: 18px;
    font-weight: 400;
    color: ${colors.gray};
  }
`
const SidebarTitle = styled.h2`
  font-size: 26px;
  color: ${colors.gray};
`

const AboutTab = () => {
  return (
    <React.Fragment>
      <SEO />
      <Main>
        <StyledGrid fluid>
          <Row>
            <Col>
              <AboutContentContainer />
            </Col>
            <Col xs='4' className='builder-side-panel'>
              <aside>
                <SidebarTitle>Escolha um valor</SidebarTitle>
                <DonationAndRewardsPane />
                <SidebarTitle>Seja um construtor</SidebarTitle>
                <p className='sidebar-description'>Escolha uma das partes da construção que você gostaria de doar</p>
                <BuilderDonationPane />
              </aside>
            </Col>
          </Row>
        </StyledGrid>
      </Main>
    </React.Fragment>
  )
}

export default AboutTab

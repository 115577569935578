import React from 'react'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import { Link, Redirect, useLocation } from 'react-router-dom'

import { isUserLogged } from '../../services/utils/user'
import { donationData } from '../../services/state/donationPurchaseData'

import SEO from '../../components/common/Seo'
import LoginEmailCheck from '../../components/forms/LoginEmailCheck'
import LoginPasswordCheck from '../../components/forms/LoginPasswordCheck'

import theme from '../../styles/theme'
import media from '../../styles/media'

import oracaoPlayLogo from '../../assets/imgs/OracaoPlay_Logo_HighRes.png'

const { colors } = theme

const Main = styled.div`
  font-size: 16px;
  background-color: ${colors.lightTint};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .main-cto {
    font-size: 20px;
    text-align: center;
    margin-bottom: 22px;
  }

  .form-container {
    background-color: #fff;
    width: 500px;
    box-shadow: 0 2px 20px #d4cae2;
    border-radius: 5px;
    padding: 36px;
    overflow-x: auto;

    ${media.tablet`
      width: 100%;
      height: 100%;
      padding: 12px;
      display: flex;
      flex-direction: column;
    `}
  }
  .form-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .form-description {
    font-size: 16px;
  }
  .col-1 {
    display: flex;
    justify-content: center;
  }
  .col {
    ${media.tinyDesktop`
      max-width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
    `}
  }
  .row {
    ${media.tinyDesktop`
      display: block;
    `}
  }
`
const HomeLogo = styled.img`
  width: 75px;
  margin: 0 auto;
  padding-bottom: 26px;
`

const GuestSignupPage = () => {
  const [donationPurchaseData] = useAtom(donationData)
  let query = new URLSearchParams(useLocation().search)

  if (isUserLogged()) {
    return <Redirect to='/' />
  }

  if (!donationPurchaseData.id) {
    return <Redirect push to='/doacao' />
  }

  return (
    <Main>
      <SEO title='Doação - Financiamento Coletivo' />
      <div className='form-container'>
        <Link to='/'>
          <HomeLogo src={oracaoPlayLogo} alt='Logo Oração Play' />
        </Link>

        {query.get('active-step') === 'user-check' ? (
          <React.Fragment>
            <h1 className='main-cto'>
              Antes de prosseguirmos com a sua doação, será necessário usar uma conta OraçãoPlay.
            </h1>
            <LoginEmailCheck />
          </React.Fragment>
        ) : null}

        {query.get('active-step') === 'password-check' ? (
          <React.Fragment>
            <h1 className='main-cto'>Insira sua senha.</h1>
            <LoginPasswordCheck
              redirectsToPaymentPage={true}
            />
          </React.Fragment>
        ) : null}
      </div>
    </Main>
  )
}

export default GuestSignupPage

import React, { useState, useEffect } from 'react'

import { Spinner } from 'reactstrap'
import BuilderDonationCard from '../home/BuilderDonationCard'
import api from '../../services/api/api'

const BuilderDonationPane = () => {
  const [builderCards, setBuilderCards] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api.get('fixed-builder-donations').then(res => {
      setTimeout(() => {
        setBuilderCards(res.data)
        setIsLoading(false)
      }, 100)
    })
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '25vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : builderCards ? (
        builderCards.map(item => (
          <BuilderDonationCard
            cardTitle={item.donation_title}
            cardDescription={item.donation_description}
            cardDonationPercentage={item.donation_percentage}
            cardDonationAmount={item.donation_value}
            cardId={item.id}
            key={item.id}
          />
        ))
      ) : null}
    </React.Fragment>
  )
}

export default BuilderDonationPane

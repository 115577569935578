import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useLocation } from 'react-router-dom'

import theme from '../../styles/theme'
import media from '../../styles/media'
import api from '../../services/api/api'

const { colors } = theme

const Main = styled.div`
  border: solid 1px ${colors.lightShade};
  border-radius: 3px;
  margin: 16px 0;
  padding: 36px;
  width: 100%;
  ${media.thone`
      width: 100%;
    `}

  .donation-heading-label {
    font-size: 18px;
    font-weight: 700;
    color: ${colors.black};
  }
  .donation-value {
    font-size: 38px;
    color: ${colors.primary};
  }
  .adress-label {
    font-weight: 700;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
    margin: 30px 0;
  }
  ul li {
    color: ${colors.gray};
    font-size: 15px;
    margin-bottom: 1.6rem;
    line-height: 18px;
  }
  p {
    font-size: 16px;
    margin-top: 16px;
  }
`

const PaymentSummaryCard = ({
  donationId,
  locationId,
  donationValue,
  paymentMethod,
  paymentMethodData,
  hasAdressData,
  adressData,
  isBuilderDonation
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [builderDonationData, setBuilderDonationData] = useState(false)

  let query = new URLSearchParams(useLocation().search)

  useEffect(() => {
    if (isBuilderDonation) {
      api.get(`builder-donation/${locationId}`).then(res => {
        setBuilderDonationData(res.data)
        setIsLoading(false)
      }).catch(() => {})
    }
  }, [locationId, isBuilderDonation])

  const getFilteredItem = function () {
    const filteredData = builderDonationData.donation_individual_items.filter(arr => arr.item_id === donationId)
    if (query.get('active-step') === 'payment-summary'  && filteredData.length > 0) {
      return filteredData[0]
    } else {
      return null
    }
  }

  return (
    <Main>
      <h3 className='donation-heading-label'>Valor do apoio:</h3>
      <span className='donation-value'>R$ {donationValue},00</span>

      {query.get('active-step') === 'payment-summary' && !isLoading && getFilteredItem() ? (
        <React.Fragment>
          <h3 style={{marginTop: 22}} className='donation-heading-label'>Item doado:</h3>
          <p style={{margin: 0}}>{getFilteredItem().item_title}</p>
          <h3 style={{marginTop: 22}} className='donation-heading-label'>Pertencente à seção:</h3>
          <p style={{margin: 0}}>{builderDonationData.donation_title}</p>
        </React.Fragment>
      ) : null}

      {hasAdressData ? (
        <React.Fragment>
          <hr />
          <h3 className='donation-heading-label'>Endereço:</h3>
          <p>
            <span className='adress-label'>Endereço:</span>
            <span> {adressData.endereco}</span>
          </p>
          <p>
            <span className='adress-label'>Número:</span>
            <span> {adressData.numero}</span>
          </p>
          <p>
            <span className='adress-label'>Bairro:</span>
            <span> {adressData.bairro}</span>
          </p>
          <p>
            <span className='adress-label'>Cidade:</span>
            <span> {adressData.cidade}</span>
          </p>
          <p>
            <span className='adress-label'>Estado:</span>
            <span> {adressData.estado}</span>
          </p>
        </React.Fragment>
      ) : null}

      {paymentMethod ? (
        <React.Fragment>
          <hr />
          <h3 className='donation-heading-label'>Método de pagamento:</h3>
          {paymentMethod === 'cartao-de-credito' ? <p>Cartão de crédito</p> : null}
          {paymentMethod === 'pix' ? <p>Pix</p> : null}
          {paymentMethod === 'transferencia' ? <p>Transferência TED/DOC</p> : null}
          {paymentMethod === 'boleto-bancario' ? <p>Boleto Bancário</p> : null}
          {paymentMethod === 'whatsapp' ? <p>Entrar em contato comigo via Whatsapp</p> : null}
          {paymentMethod === 'paypal' ? <p>Paypal</p> : null}

          {paymentMethodData.parcela !== 1 && paymentMethod === 'cartao-de-credito' ? (
            <React.Fragment>
              <h3 style={{ marginTop: 32 }} className='donation-heading-label'>Parcelamento:</h3>
              <p>{paymentMethodData.parcela}x no cartão de crédito</p>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </Main>
  )
}

export default PaymentSummaryCard

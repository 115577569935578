import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import OneSignal from 'react-onesignal'

import { Link, useHistory } from 'react-router-dom'
import { Progress, Spinner, Modal, ModalBody } from 'reactstrap'
import GlobalButton from '../common/GlobalButton'
import { AiFillFacebook, AiFillTwitterSquare } from 'react-icons/ai'
import { MdMail, MdShare } from 'react-icons/md'

import { isUserLogged } from '../../services/utils/user'
import api from '../../services/api/api'
import theme from '../../styles/theme'

require('share-api-polyfill')

const { colors } = theme

const Main = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .progress-bar {
    background-color: ${colors.secondary};
  }
  .lower-buttons {
    margin-top: 5px;
    display: flex;
  }
  .sharing {
    margin-left: 16px;
    display: flex;
    align-items: center;
  }
  .social-icon {
    width: 36px;
    height: 36px;
    color: ${colors.secondary};
  }

  .horizontal-data-container {
    display: flex;
  }
`
const DataContainer = styled.div`
  margin: ${props => (props.isFirstData ? '16px 0 16px' : '16px 32px 16px 0')};
`
const CTAText = styled.p`
  margin-bottom: 16px;
  font-size: 22px;
  color: ${colors.gray};
`
const NumberData = styled.h4`
  font-size: 34px;
  color: ${props => (props.highlightData ? `${colors.primary}` : `${colors.gray}`)};
`
const LabelData = styled.p`
  font-size: 16px;
  color: ${colors.gray};
`
const StyledModalBody = styled(ModalBody)`
  padding: 32px;

  p {
    font-size: 16px;
    margin-bottom: 16px;
  }
  h1 {
    font-size: 32px;
    margin-bottom: 16px;
  }
`

const HomeDonationChart = () => {
  const history = useHistory()

  const [donationData, setDonationData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState(false)
  const [notificationIsLoading, setNotificationIsLoading] = useState(false)
  const [notificationStatus, setNotificationStatus] = useState(false)

  const closeModal = () => setNotificationModalIsOpen(false)
  const openModal = () => setNotificationModalIsOpen(true)

  useEffect(
    () => {
      api.get('site-metadata').then(res => {
        setDonationData(res.data)
        setIsLoading(false)
      })
    },
    [],
    false
  )

  const onRemindMeClick = async function () {
    try {
      /* Sends a request for permission to send notifications to the browser */

      await OneSignal.registerForPushNotifications()
      const currentState = await OneSignal.getNotificationPermission()

      /* Guard to prevent unregistered users to set notifications */
      if (!isUserLogged()) {
        history.push('/login')
      }

      /* Sets the message on the modal to request the user for permission */
      if (currentState === 'default') {
        setNotificationIsLoading(false)
        setNotificationStatus('not-granted')
        openModal()
      }
      /* Sets the message on the modal request to a sucess if the permission is granted */
      if (currentState === 'granted') {
        api
          .post('register-push', { playerId: await OneSignal.getPlayerId() })
          .then(() => {
            setNotificationIsLoading(false)
            setNotificationStatus('granted')
            openModal()
          })
          .catch(() => {
            setNotificationStatus('error')
            openModal()
          })
      } else {
        setNotificationStatus('error')
        openModal()
      }
    } catch {
      setNotificationStatus('error')
      openModal()
    }
  }

  const onGenericShare = async function () {
    try {
      await navigator.share(
        {
          title: 'Financiamento Coletivo - OraçãoPlay',
          text: 'Ajude os irmãos carmelitas a construir esse novo espaço de amor e oração.',
          url: 'doacao.carmelitasmensageiros.org',
        },
        {
          copy: true,
          email: true,
          print: true,
          sms: true,
          messenger: true,
          facebook: false,
          whatsapp: true,
          twitter: false,
          linkedin: true,
          telegram: true,
          skype: true,
          language: 'pt',
        }
      )
    } catch {}
  }

  return (
    <Main>
      <Modal isOpen={notificationModalIsOpen}>
        <StyledModalBody>
          {notificationIsLoading ? <Spinner color='secondary' /> : null}
          {notificationStatus === 'granted' ? (
            <div style={{ textAlign: 'center' }}>
              <h1>Notificações ativadas com sucesso!</h1>
              <p>
                A partir de agora você receberá notificações sobre o Financiamento Coletivo em todos os dispositivos com
                OraçãoPlay que usarem esta conta.
              </p>
            </div>
          ) : null}
          {notificationStatus === 'not-granted' ? (
            <div style={{ textAlign: 'center' }}>
              <h1>Oops!</h1>
              <p>
                Parece que você não permitiu que lhe enviássemos notificações. Permita as notificações no seu navegador
                e tente novamente.
              </p>
            </div>
          ) : null}
          {notificationStatus === 'error' ? (
            <div style={{ textAlign: 'center' }}>
              <h1>Oops!</h1>
              <p>Ocorreu um erro ao ativar as notificações. Tente novamente mais tarde.</p>
            </div>
          ) : null}
          <GlobalButton buttonLabel='OK' onClick={() => closeModal()} />
        </StyledModalBody>
      </Modal>

      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px' }}>
          <Spinner color='secondary' />
        </div>
      ) : (
        <React.Fragment>
          <CTAText>Ajude a construir o convento:</CTAText>
          <Progress value={donationData.homepage_donated_percentage} />
          <React.Fragment>
            <DataContainer isFirstData>
              <NumberData>{donationData.homepage_donated_percentage}% do total</NumberData>
            </DataContainer>
            <div className='horizontal-data-container'>
              <DataContainer>
                <NumberData>{donationData.homepage_supporters_ammount}</NumberData>
                <LabelData>Apoiadores</LabelData>
              </DataContainer>
              <DataContainer>
                <NumberData>{donationData.homepage_days_remaining}</NumberData>
                <LabelData>Dias Restantes</LabelData>
              </DataContainer>
            </div>
          </React.Fragment>

          <Link to='/escolher-doacao'>
            <GlobalButton
              style={{ marginBottom: 22 }}
              large={true}
              dropShadow={true}
              background={colors.tertiary}
              labelColor={colors.light}
              border={colors.tertiary}
              hoverColor={colors.tertiary}
              buttonLabel='QUERO APOIAR'
            />
          </Link>

          <div className='lower-buttons'>
            <GlobalButton
              onClick={() => onRemindMeClick()}
              large={false}
              dropShadow={false}
              background={colors.light}
              labelColor={colors.primary}
              border={colors.primary}
              hoverColor={colors.lightShade}
              buttonLabel='Me lembre'
            />
            <div className='sharing'>
              <a
                className='resp-sharing-button__link'
                href='https://facebook.com/sharer/sharer.php?u=doacao.carmelitasmensageiros.org'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Compartilhar no Facebook'
              >
                <AiFillFacebook className='social-icon' />
              </a>

              <a
                className='resp-sharing-button__link'
                href='https://twitter.com/intent/tweet/?text=Financiamento%20Coletivo%20-%20Ora%C3%A7%C3%A3oPlay%20%7C%20Ajude%20os%20irm%C3%A3os%20carmelitas%20a%20construir%20esse%20novo%20espa%C3%A7o%20de%20amor%20e%20ora%C3%A7%C3%A3o.&amp;url=doacao.carmelitasmensageiros.org'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Compartilhar no Twitter'
              >
                <AiFillTwitterSquare className='social-icon' />
              </a>

              <a
                className='resp-sharing-button__link'
                href='mailto:?subject=Financiamento%20Coletivo%20-%20Ora%C3%A7%C3%A3oPlay%20%7C%20Ajude%20os%20irm%C3%A3os%20carmelitas%20a%20construir%20esse%20novo%20espa%C3%A7o%20de%20amor%20e%20ora%C3%A7%C3%A3o.&amp;body=doacao.carmelitasmensageiros.org'
                target='_self'
                rel='noopener'
                aria-label='Compartilhar por email'
              >
                <MdMail className='social-icon' />
              </a>
              {navigator.share ? (
                <div style={{ cursor: 'pointer' }} onClick={() => onGenericShare()}>
                  <MdShare className='social-icon' />
                </div>
              ) : null}
            </div>
          </div>
        </React.Fragment>
      )}
    </Main>
  )
}

export default HomeDonationChart

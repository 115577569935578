import React from 'react'
import styled from 'styled-components'
import { Progress } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import { formatMonetary } from '../../services/utils/transforms'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'

import GlobalButton from '../common/GlobalButton'


const { colors } = theme

const Main = styled.div`
  ${mixins.rewardDonationCardAnimation};
  ${mixins.sidePaneCards};
  .progress-bar {
    background-color: ${colors.secondary};
  }
`
const CardTitle = styled.h3`
  text-align: center;
  font-size: 24px;
  color: ${colors.primary};
`
const CardDescription = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.black};
  margin: 16px 0;
`
const DonationRepresentativePercentage = styled.p`
  color: ${colors.gray};
  font-size: 18px;
  font-weight: 400;
  margin: 6px 0 32px 0;
`

const BuilderDonationCard = ({ cardTitle, cardDescription, cardDonationPercentage, cardDonationAmount, cardId }) => {
  const history = useHistory()
  const builderItemClick = function () {
    history.push(`/construtor/${cardId}`)
  }

  return (
    <Main onClick={builderItemClick}>
      <CardTitle>{cardTitle}</CardTitle>
      <CardTitle>R$ {formatMonetary(cardDonationAmount, 0, '', '.' )}</CardTitle>
      <CardDescription>{cardDescription}</CardDescription>
      <Progress value={cardDonationPercentage} />

      {cardId === 'selecionar-local' ? (
        <DonationRepresentativePercentage>{cardDonationPercentage}% já construído</DonationRepresentativePercentage>
      ) : (
        <DonationRepresentativePercentage>
          {cardDonationPercentage}% desta seção já construída
        </DonationRepresentativePercentage>
      )}

      <GlobalButton
        large={false}
        background={colors.tertiary}
        labelColor={colors.light}
        border={colors.tertiary}
        hoverColor={colors.tertiary}
        buttonLabel='Saiba mais'
      />
    </Main>
  )
}

export default BuilderDonationCard

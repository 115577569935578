import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useAtom } from 'jotai'
import { userData } from '../../services/state/appState'

import media from '../../styles/media'
import theme from '../../styles/theme'

import UserBadge from './UserBadge'
import oracaoPlayLogo from '../../assets/imgs/Doacao_Logo.png'
import MenuBadge from './MenuBadge'

const { colors } = theme

const Main = styled.nav`
  height: 50px;
  font-size: 15px;
  background: ${colors.background};
  border-bottom: 1px solid ${colors.lightShade};
  top: 0;
  z-index: 15;
`
const GridContainer = styled.div`
  ${media.phablet`padding: 5px 15px;`}
  padding: 5px 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo-container {
    display: flex;
    align-items: center;
  }
  img {
    height: 40px;
    width: auto;
  }
`
const LogoText = styled.p`
  ${media.tablet`
    max-width: 50vw;
    font-size: 15px;
    line-height: 16px;
  `}
  font-size: 18px;
  color: ${colors.secondary};
  font-weight: 700;
  padding-left: 10px;
  letter-spacing: -1px;
`

const NavHeader = function () {
  const [atomUserData] = useAtom(userData)

  return (
    <Main>
      <GridContainer>
        <Link to='/'>
          <div className='logo-container'>
            <img src={oracaoPlayLogo} alt='Logo Oração Play' />
            <LogoText>Carmelitas Mensageiros do Espírito Santo</LogoText>
          </div>
        </Link>
        <div>
          {atomUserData ? (
            <React.Fragment>
              <UserBadge userName={atomUserData.nome_abreviado} userImage={atomUserData.photo.s} />
            </React.Fragment>
          ) : (
            <MenuBadge />
          )}
        </div>
      </GridContainer>
    </Main>
  )
}

export default NavHeader

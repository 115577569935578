import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CookieConsentContainer from './components/common/CookieConsent'
import ScrollToTop from './services/utils/scrollHandler'

import LoginPage from './pages/authentication/LoginPage'
import SignupPage from './pages/authentication/SignupPage'
import RecoverPassword from './pages/authentication/RecoverPassword'
import UserProfile from './pages/authentication/UserProfile'
import GuestSignupPage from './pages/authentication/GuestSignupPage'

import AboutTab from './pages/home/AboutTab'
import FaqTab from './pages/home/FaqTab'
import ProgressTab from './pages/home/ProgressTab'
import BuilderTab from './pages/home/BuilderTab'
import ProgressIndividualPost from './pages/home/ProgressIndividualPost'

import PaymentPage from './pages/payment/Payment'
import ReturnPayment from './pages/payment/ReturnPayment'
import DonationPage from './pages/donation/DonationPage'
import DonationChoice from './pages/donation/DonationChoice'
import BuilderPage from './pages/builder/BuilderPage'
import TermsOfPrivacy from './pages/termsOfPrivacy/TermsOfPrivacy'

import Layout from './components/common/Layout'
import Hero from './components/common/Hero'
import MainPageContent from './components/common/MainPageContent'
import HomeTabs from './components/home/HomeTabs'
import FabOverlay from './components/common/FabOverlay'

class Routes extends React.Component {
  render() {
    /* Separated some routes in their own functions and injected them back in the route-tree later
    since I need to leverage some components that will remain static across multiple routes, like in
    the homepage, where the top level layout stays the same across all tab routes */
    const HomeRoutes = () => {
      return (
        <React.Fragment>
          <Layout>
            <Hero
              background={true}
              heroTitle='Convento Espírito Santo Paráclito e Maria Mãe da Igreja'
              heroDescription='Um lugar para Jesus ser adorado!'
            />
            <MainPageContent />
            <HomeTabs />

            <div id='home-tabs-container'>
              <Switch>
                {/* Redirecting homepage straight to /sobre to make sure the tab will receive the
              active-link class */}
                <Route exact path='/' component={AboutTab} />
                <Route path='/sobre' component={AboutTab} />
                <Route path='/faq' component={FaqTab} />
                <Route path='/progresso' exact component={ProgressTab} />
                <Route path='/progresso/page/:pageId' component={ProgressTab} />
                <Route path='/progresso/posts/:postId' component={ProgressIndividualPost} />
                <Route path='/seja-um-construtor' component={BuilderTab} />

              </Switch>
            </div>
            <CookieConsentContainer />
          </Layout>
        </React.Fragment>
      )
    }

    return (
      <BrowserRouter>
        <ScrollToTop />
        <FabOverlay />
        <Switch>
          <Route path='/login' component={LoginPage} />
          <Route path='/signup' component={SignupPage} />
          <Route path='/guest-login' component={GuestSignupPage} />
          <Route path='/recuperar-senha' component={RecoverPassword} />
          <Route path='/editar-perfil' component={UserProfile} />
          <Route path='/escolher-doacao' component={DonationChoice} />
          <Route path='/doacao' component={DonationPage} />
          <Route path='/pagamento' component={PaymentPage} />
          <Route path='/payment-return' component={ReturnPayment} />
          <Route path='/termos-de-privacidade' component={TermsOfPrivacy} />
          <Route path='/construtor/:mapId' component={BuilderPage} />
          <Route component={HomeRoutes} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Routes

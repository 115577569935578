import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'
import legacyApi from '../../services/api/legacyApi'

import { Link } from 'react-router-dom'
import SEO from '../../components/common/Seo'
import GlobalButton from '../../components/common/GlobalButton'
import { Modal, ModalBody } from 'reactstrap'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import media from '../../styles/media'

import oracaoPlayLogo from '../../assets/imgs/OracaoPlay_Logo_HighRes.png'

const { colors } = theme

const Main = styled.div`
  font-size: 16px;
  background-color: ${colors.lightTint};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .form-container {
    background-color: #fff;
    width: 500px;
    box-shadow: 0 2px 20px #d4cae2;
    border-radius: 5px;
    padding: 36px;
    overflow-x: auto;

    ${media.tablet`
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    `}
  }
  .form-description {
    font-size: 22px;
    text-align: center;
  }
`
const HomeLogo = styled.img`
  width: 75px;
  margin: 0 auto;
  padding-bottom: 26px;
`
const StyledForm = styled.form`
  ${mixins.formStyles}
`
const StyledModalBody = styled(ModalBody)`
  padding: 32px;

  p {
    font-size: 18px;
  }
`

const RecoverPasswordPage = () => {
  const history = useHistory()

  let query = new URLSearchParams(useLocation().search)

  const [sucessModal, setSucessModal] = useState(false)
  const [notFoundError, setNotFoundError] = useState(false)

  const redirectToLogin = () => {
    if (query.has('mail')) {
      history.push(`/guest-login?active-step=password-check&from=password-recovery`)
    } else {
      history.push('/login')
    }
  }

  return (
    <Main>
      <SEO title='Recuperar senha - Financiamento Coletivo' />

      <Modal isOpen={sucessModal}>
        <StyledModalBody>
          <p>Sua senha de recuperação foi enviada. Por favor, verifique seu email e/ou número de telefone.</p>
          <GlobalButton
            style={{ marginTop: 22 }}
            onClick={() => redirectToLogin()}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primaryShade}
            buttonLabel='Ok'
          />
        </StyledModalBody>
      </Modal>

      <div className='form-container'>
        <Link to='/'>
          <HomeLogo src={oracaoPlayLogo} alt='Logo Oração Play' />
        </Link>
        <h3 className='form-description'>Digite seu email para recuperar sua senha.</h3>

        <Formik
          initialValues={{
            login: query.has('mail') ? query.get('mail') : '',
            op: 'esqueciSenha',
          }}
          validationSchema={Yup.object().shape({
            login: Yup.string().required(
              'É necessário fornecer um endereço de email já cadastrado para recuperar a senha.'
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            legacyApi
              .post('', {
                login: values.login,
                op: 'esqueciSenha',
              })
              .then(response => {
                if (response.data) {
                  setSucessModal(true)
                } else {
                  setNotFoundError(true)
                }
              })
              .catch(err => {
                setNotFoundError(true)
              })

            setSubmitting(false)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <StyledForm onSubmit={handleSubmit}>
              <div className='form-control-container'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  inputMode='email'
                  autoComplete='email'
                  name='login'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.login}
                />
                {errors.login && touched.login && <div className='validation-error'>{errors.login}</div>}

                {notFoundError ? <div className='validation-error'>Email não encontrado.</div> : null }
              </div>

              <GlobalButton
                large={false}
                dropShadow={true}
                background={colors.primary}
                labelColor={colors.light}
                border={colors.primary}
                hoverColor={colors.primary}
                buttonLabel='Recuperar a senha'
                type='submit'
              />
            </StyledForm>
          )}
        </Formik>
      </div>
    </Main>
  )
}

export default RecoverPasswordPage

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'
import api from '../../services/api/api'

import SEO from '../../components/common/Seo'
import NavHeader from '../../components/common/NavHeader'
import NavFooter from '../../components/common/NavFooter'
import SelectLocation from '../../components/builder/SelectLocation'
import { Spinner } from 'reactstrap'
import SelectItem from '../../components/builder/SelectItem'

const { colors } = theme

const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  padding-top: 60px;

  .breadcrumb {
    background-color: ${colors.light};
    font-size: 16px;
  }
  .page-title {
    font-size: 34px;
    color: ${colors.black};
    margin: 32px 0 16px;
  }
  .page-description {
    font-size: 16px;
    color: ${colors.gray};
    padding-bottom: 16px;
  }
`

const BuilderPage = props => {
  let mapId = props.match.params.mapId

  const [builderData, setBuilderData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentMapId, setCurrentMapId] = useState(null)

  useEffect(() => {
    if (mapId !== currentMapId) {
      setIsLoading(true)
    }

    api.get(`builder-donation/${mapId}`).then(res => {
      setTimeout(() => {
        setCurrentMapId(mapId)
        setBuilderData(res.data)
        setIsLoading(false)
      }, 100)
    }).catch(() => {})
  }, [mapId, currentMapId])

  return (
    <React.Fragment>
      <SEO title='Seja um Construtor - OraçãoPlay' />
      <NavHeader />
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : (
        <Main>
          {currentMapId === 'selecionar-local' ? (
            <SelectLocation
              donationId={builderData.id}
              donationTitle={builderData.donation_title}
              donationDescription={builderData.donation_description}
              donationImg={builderData.donation_img}
              donationImgAlt={builderData.donation_img_alt}
              donationPercentage={builderData.donation_percentage}
            />
          ) :
            <SelectItem
              locationTitle={builderData.donation_title}
              locationDescription={builderData.donation_description}
              locationId={builderData.id}
            />}
        </Main>
      )}
      <NavFooter />
    </React.Fragment>
  )
}

export default BuilderPage

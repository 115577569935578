import React, { useState } from 'react'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import { Formik } from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'

import legacyApi from '../../services/api/legacyApi'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

import GlobalButton from '../common/GlobalButton'
import AuthenticationAlert from '../common/AuthenticationAlert'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { guestLogin, userData, fabOverlayModal, fabOverlayModalMessage } from '../../services/state/appState'
import { loginUser } from '../../services/utils/user'

const StyledForm = styled.form`
  ${mixins.formStyles}
`

const { colors } = theme

const LoginEmailCheck = props => {
  const history = useHistory()

  let query = new URLSearchParams(useLocation().search)

  const [incorrectPassword] = useState(false)
  const [loginRequestError, setLoginRequestError] = useState(false)

  const [guestLoginAtom] = useAtom(guestLogin)
  const [, setAtomUserData] = useAtom(userData)
  const [, setAtomModal] = useAtom(fabOverlayModal)
  const [, setAtomModalMessage] = useAtom(fabOverlayModalMessage)

  return (
    <Formik
      initialValues={{
        senha: '',
      }}
      validationSchema={Yup.object().shape({
        senha: Yup.string(),
        
        celular: Yup.string()
          .matches(/^\(?0?[1-9]{2}\)?[- ]?9[0-9]{4}[- ]?[0-9]{4}$/, 'Insira um número de celular válido'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        legacyApi
          .post('', {
            login: guestLoginAtom,
            senha: values.senha,
            celular: values.celular,
            fromKickstarter: 1,
            op: 'login',
          })
          .then(async response => {
            if (response.data) {
              loginUser(response.data, true)
              setAtomUserData(response.data)

              if (props.redirectsToPaymentPage) {
                history.push('/pagamento?active-step=payment-method')
              }
            } else {
              await setAtomModalMessage('Oi. Estou com problemas para efetuar meu login. Por favor me ajudem.')
              await setAtomModal(true)
            }
          })
          .catch(() => {
            setLoginRequestError(true)
          })
        setSubmitting(false)
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>

          {query.has('from') && query.get('from') === 'user-check' ? (
            <AuthenticationAlert
              alertHighlight={colors.successHighlight}
              alertBackground={colors.successBackground}
              alertSuccess={true}
            >
              <p className='alert-label'>
                Detectamos que você já possui uma conta OraçãoPlay! Entre com a sua senha abaixo.
              </p>
            </AuthenticationAlert>
          ) : null}

          {query.has('from') && query.get('from') === 'password-recovery' ? (
            <AuthenticationAlert
              alertHighlight={colors.successHighlight}
              alertBackground={colors.successBackground}
              alertSuccess={true}
            >
              <p className='alert-label'>
                Você solicitou uma recuperação de senha que foi enviada para o email{' '}
                <b>{guestLoginAtom.toLowerCase()}</b>.
              </p>
            </AuthenticationAlert>
          ) : null}

          {loginRequestError ? (
            <AuthenticationAlert
              alertHighlight={colors.warningHighlight}
              alertBackground={colors.warningBackground}
              alertWarning={true}
            >
              <p className='alert-label'>
                Houve um erro ao processar o seu pedido. Por favor, tente novamente mais tarde.
              </p>
            </AuthenticationAlert>
          ) : null}

          {incorrectPassword ? (
            <AuthenticationAlert
              alertHighlight={colors.warningHighlight}
              alertBackground={colors.warningBackground}
              alertWarning={true}
            >
              <p className='alert-label'>
                A senha está incorreta. Caso tenha esquecido a senha, clique em <b>Recuperar Senha</b>.
              </p>
            </AuthenticationAlert>
          ) : null}

          <div className='form-control-container'>
            <input
              type='password'
              inputMode='password'
              autoComplete='password'
              name='senha'
              placeholder='Senha'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.senha}
            />
          </div>
          <p style={{ fontSize: 18 }}>ou</p> 
          <div className='form-control-container'>
            <InputMask
              type='tel'
              name='celular'
              placeholder='Telefone Celular'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.celular}
              mask='(99)99999-9999'
            />
          </div>

          <GlobalButton
            large={false}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primary}
            buttonLabel='Prosseguir'
            type='submit'
          />

          <div style={{ textAlign: 'center', marginTop: '32px' }}>
            <span>
              <p className='sub-text' style={{ marginBottom: 12 }}>
                Esqueçeu sua senha?
              </p>
              <Link to={`/recuperar-senha?mail=${guestLoginAtom}&from=guest`} className='signup-link'>
                <GlobalButton
                  background={colors.light}
                  labelColor={colors.primary}
                  border={colors.primary}
                  hoverColor={colors.lightShade}
                  buttonLabel='Recuperar senha'
                  type='button'
                />
              </Link>
            </span>
          </div>

          <FormikErrorFocus
            offset={0}
            align={'top'}
            focusDelay={50}
            ease={'linear'}
            duration={1000}
          />
        </StyledForm>
      )}
    </Formik>
  )
}

export default LoginEmailCheck

import React from 'react'
import styled from 'styled-components'
import { useAtom } from 'jotai'

import { userData } from '../../services/state/appState'

import { Link, Redirect, useLocation } from 'react-router-dom'
import SEO from '../../components/common/Seo'
import SignupForm from '../../components/forms/SignupForm'

import theme from '../../styles/theme'
import media from '../../styles/media'

import oracaoPlayLogo from '../../assets/imgs/OracaoPlay_Logo_HighRes.png'

const { colors } = theme

const Main = styled.div`
  font-size: 16px;
  background-color: ${colors.lightTint};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .form-container {
    margin-top: 32px;
    margin-bottom: 32px;
    background-color: #fff;
    width: 500px;
    box-shadow: 0 2px 20px #d4cae2;
    border-radius: 5px;
    padding: 36px;
    overflow-x: auto;

    ${media.tablet`
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
    `}
  }
  .form-description {
    font-size: 18px;
    text-align: center;
  }
`
const HomeLogo = styled.img`
  width: 75px;
  margin: 0 auto;
  padding-bottom: 26px;
`

const LoginPage = () => {
  const [atomUserData] = useAtom(userData)

  let query = new URLSearchParams(useLocation().search)

  if (atomUserData) {
    if (query.has('from')) {
      if (query.get('from') === 'guest') {
        return <Redirect to='/pagamento?active-step=payment-method' />
      }
    }
    return <Redirect to='/' />
  }

  return (
    <Main>
      <SEO title='Inscrever-se - Financiamento Coletivo' />
      <div className='form-container'>
        <Link to='/'>
          <HomeLogo src={oracaoPlayLogo} alt='Logo Oração Play' />
        </Link>
        <p className='form-description'>Crie uma nova conta para fazer doações e acessar o aplicativo OraçãoPlay.</p>
        <SignupForm />
      </div>
    </Main>
  )
}

export default LoginPage

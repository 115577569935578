import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import { donationData } from '../../services/state/donationPurchaseData'

import { InputGroup, InputGroupAddon, InputGroupText, Input, Container, Row, Col } from 'reactstrap'
import GlobalButton from '../common/GlobalButton'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { colors } = theme

const Main = styled.div`
  ${mixins.rewardDonationCard};
`
const StyledContainer = styled(Container)`
  .row {
    align-items: center;
  }
  .col,
  .col-4 {
    padding-left: 0;
    padding-right: 10px;
  }
  margin-top: 32px;
  .input-group {
    font-size: 16px;
  }
  .input-group-text {
    font-size: 18px;
    color: ${colors.light};
    background-color: ${colors.primary};
  }
  .form-control {
    height: 52px;
    font-size: 18px;
    border: 1px solid ${colors.primary};
  }
`
const StyledForm = styled.form`
  ${mixins.formStyles}
  display: flex;
`

const DonationItem = ({
  donationTitle,
  donationDescription,
  donationValue,
  donationId,
  hasDonationInput,
  hasDonationRewards,
  donationRewards,
  children,
}) => {
  const history = useHistory()
  const [donationPurchaseData, setDonationPurchaseData] = useAtom(donationData)

  // The function must push the user to the payment route and update the state on the donationOption that was chosen
  const onDonationItemClick = function (donationId, donationValue, hasDonationRewards, donationRewards) {
    // ...donationPurchaseData.rewards,
    setDonationPurchaseData({
      ...donationPurchaseData,
      id: donationId,
      value: donationValue,
      isBuilderDonation: false,
      hasRewards: hasDonationRewards,
      rewards: donationRewards,
    })

    history.push(`/pagamento?active-step=payment-method`)
  }

  return (
    <Main>
      <div className='visible-section'>
        <div>
          <h3 className='card-title'>{donationTitle}</h3>
          <p className='card-description'>{donationDescription}</p>
          {hasDonationRewards ? <p className='card-rewards-title'>Recompensas:</p> : null}
          {children}
        </div>
      </div>
      <StyledContainer>
        <Row>
          {hasDonationInput ? (
            <Formik
              initialValues={{
                donation_value: '15',
              }}
              validationSchema={Yup.object().shape({
                donation_value: Yup.string().required('Insira um valor acima de 15 reais.'),
              })}
              onSubmit={(values, { setSubmitting }) => {

                setDonationPurchaseData({
                  ...donationPurchaseData,
                  id: donationId,
                  value: values.donation_value,
                  isBuilderDonation: false,
                  hasRewards: false
                })

                history.push(`/pagamento?active-step=payment-method`)

                setSubmitting(false)
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <Col>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>R$</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type='number'
                        min='15'
                        inputMode='numeric'
                        name='donation_value'
                        value={values.donation_value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </InputGroup>
                    {errors.donation_value && touched.donation_value && (
                      <div className='validation-error'>{errors.donation_value}</div>
                    )}
                  </Col>
                  <Col xs='4'>
                    <GlobalButton
                      buttonLabel='Doar'
                      large={false}
                      dropShadow={true}
                      background={colors.primary}
                      labelColor={colors.light}
                      borderColor={colors.primary}
                      hoverColor={colors.primaryShade}
                      type='submit'
                    />
                  </Col>
                </StyledForm>
              )}
            </Formik>
          ) : null}

          {hasDonationInput ? null : (
            <Col xs='4'>
              <GlobalButton
                buttonLabel={`Doar R$ ${donationValue}`}
                large={false}
                dropShadow={true}
                background={colors.primary}
                labelColor={colors.light}
                borderColor={colors.primary}
                hoverColor={colors.primaryShade}
                onClick={() => onDonationItemClick(donationId, donationValue, hasDonationRewards, donationRewards)}
              />
            </Col>
          )}
        </Row>
      </StyledContainer>
    </Main>
  )
}

export default DonationItem

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { useLocation, Redirect, Link } from 'react-router-dom'
import { useAtom } from 'jotai'
import QRCode from 'react-qr-code'
import {CopyToClipboard} from 'react-copy-to-clipboard';

import SEO from '../../components/common/Seo'
import Layout from '../../components/common/Layout'
import GlobalButton from '../../components/common/GlobalButton'
import AuthenticationAlert from '../../components/common/AuthenticationAlert'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import media from '../../styles/media'

import { isUserLogged } from '../../services/utils/user'
import api from '../../services/api/api'
import { userData } from '../../services/state/appState'
import { donationData } from '../../services/state/donationPurchaseData'

const { colors } = theme

const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
`
const HandleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .button-flex {
    max-width: 280px;
    margin-top: 22px;
    ${media.phablet`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 100%;
    `}
  }

  button {
    margin-bottom: 16px;
  }

  h1 {
    font-size: 34px;
    color: ${colors.black};
    margin: 32px 0 16px;
  }
  p {
    font-size: 16px;
    color: ${colors.gray};
    max-width: 900px;
  }
  strong {
    font-weight: 700;
  }
  .transaction-id {
    font-size: 20px;
    margin-top: 38px;
  }
`

const HandleErrorCase = ({ query }) => {
  const [atomUserData] = useAtom(userData)
  const onHelpRequest = () => {
    api
      .post('payment/help', {
        cmesToken: atomUserData.token,
      })
      .then(response => {
        if (response.data) {
          alert('O seu pedido foi registrado. Em breve algum dos nossos irmãos entrará em contato com você.')
        }
      })
      .catch(() => {
        alert(
          'Não conseguimos registrar o seu pedido. Talvez o seu pedido já esteja registrado, ou nossos servidores estejam indisponíveis.'
        )
      })
  }

  return (
    <HandleWrapper>
      {query.get('errorMessage') ? (
        <React.Fragment>
          <h1>Ocorreu um erro ao processar seu pagamento!</h1>
          <p>{query.get('errorMessage')}</p>
          <div className='button-flex'>
            <Link to='/'>
              <GlobalButton
                style={{ padding: '16px 22px' }}
                dropShadow={false}
                background={colors.light}
                labelColor={colors.primary}
                border={colors.primary}
                hoverColor={colors.lightShade}
                buttonLabel='Voltar ao Início'
              />
            </Link>
            <Link to='/pagamento?active-step=payment-data&payment-method=cartao-de-credito'>
              <GlobalButton
                style={{ padding: '16px 22px' }}
                dropShadow={false}
                background={colors.light}
                labelColor={colors.primary}
                border={colors.primary}
                hoverColor={colors.lightShade}
                buttonLabel='Tentar novamente'
              />
            </Link>
            <GlobalButton
              style={{ padding: '16px 22px' }}
              dropShadow={false}
              background={colors.light}
              labelColor={colors.primary}
              border={colors.primary}
              hoverColor={colors.lightShade}
              buttonLabel='Preciso de ajuda'
              onClick={() => onHelpRequest()}
            />
          </div>
        </React.Fragment>
      ) : null}
    </HandleWrapper>
  )
}

const HandleSucessCase = ({ query }) => {
  const [clipboardCopied, setClipboardCopied] = useState(false)
  const [messageContent, setMessageContent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [atomUserData] = useAtom(userData)

  useEffect(() => {
    api.get(`config/pix-description`).then(res => {
      setTimeout(() => {
        setMessageContent(res.data)
        setIsLoading(false)
      }, 100)
    })
  }, [])

  const onHelpRequest = () => {
    api
      .post('payment/help', {
        cmesToken: atomUserData.token,
      })
      .then(response => {
        if (response.data) {
          alert('O seu pedido foi registrado. Em breve algum dos nossos irmãos entrará em contato com você.')
        }
      })
      .catch(() => {
        alert(
          'Não conseguimos registrar o seu pedido. Talvez o seu pedido já esteja registrado, ou nossos servidores estejam indisponíveis.'
        )
      })
  }

  return (
    <HandleWrapper>
      {query.get('paymentForm') === 'boleto-bancario' ? (
        query.get('boletoUrl') ? (
          <React.Fragment>
            <h1>Transação realizada com sucesso!</h1>
            <p>
              Baixe ou imprima o boleto e pague-o em uma agência, lotérica, ou aplicativo de internet banking. Seu
              pagamento é aprovado em até 2 dias úteis, e você receberá um email de confirmação.
            </p>
            <div className='button-flex'>
              <a href={query.get('boletoUrl')} target='_blank' rel='noreferrer noopener'>
                <GlobalButton style={{ padding: '16px 22px' }} buttonLabel='Baixar o Boleto' />
              </a>
              <Link to='/'>
                <GlobalButton
                  style={{ padding: '16px 22px' }}
                  dropShadow={false}
                  background={colors.light}
                  labelColor={colors.primary}
                  border={colors.primary}
                  hoverColor={colors.lightShade}
                  buttonLabel='Voltar ao Início'
                />
              </Link>
            </div>
          </React.Fragment>
        ) : null
      ) : query.get('status') === 'APPROVED' ? (
        query.get('cardTransactionId') ? (
          <React.Fragment>
            <h1>Transação realizada com sucesso!</h1>
            <p>Os detalhes de confirmação da compra serão enviados para o seu email.</p>
            <p className='transaction-id'>
              Código da transação: <strong>{query.get('cardTransactionId')}</strong>
            </p>
            <div className='button-flex'>
              <Link to='/'>
                <GlobalButton
                  style={{ padding: '16px 22px' }}
                  dropShadow={false}
                  background={colors.light}
                  labelColor={colors.primary}
                  border={colors.primary}
                  hoverColor={colors.lightShade}
                  buttonLabel='Voltar ao Início'
                />
              </Link>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {query.get('qrCode') ? <h1>Escaneeie o QRCode abaixo para completar sua transação:</h1> : <h1>Transação realizada com sucesso!</h1>}
            <p>{query.get('successMessage')}</p>
            <div>
              {query.get('qrCode') ? (
                <div style={{ maxWidth: 500}}>
                  <div style={{marginBottom: 32, marginTop: 32}}>
                    <QRCode value={query.get('qrCode')} />
                  </div>
                  {!isLoading && messageContent ? (
                    <div dangerouslySetInnerHTML={{__html: messageContent}} />
                  ) : null}
                  {clipboardCopied ? (
                    <AuthenticationAlert alertHighlight={colors.successHighlight} alertBackground={colors.successBackground} alertSuccess={true}>
                      <p>Código copiado</p>
                    </AuthenticationAlert>
                  ) : null}
                  <p style={{marginBottom: 16}}>Você também pode copiar o código e usá-lo na função "Pix Copia e Cola" do seu aplicativo bancário:</p>
                  <CopyToClipboard
                    text={query.get('qrCode')}
                    onCopy={() => setClipboardCopied(true)}
                  >
                    <div className='button-flex'>
                      <GlobalButton
                        buttonLabel='Copiar código PIX'
                      />
                    </div>
                  </CopyToClipboard>
                </div>
              ) : null}
            </div>
            <div className='button-flex'>
              <Link to='/'>
                <GlobalButton
                  style={{ padding: '16px 22px' }}
                  dropShadow={false}
                  background={colors.light}
                  labelColor={colors.primary}
                  border={colors.primary}
                  hoverColor={colors.lightShade}
                  buttonLabel='Voltar ao Início'
                />
              </Link>
            </div>
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <h1>Transação negada!</h1>
          <p>{query.get('errorMessage')}</p>
          <div className='button-flex'>
            <Link to='/pagamento?active-step=payment-data&payment-method=cartao-de-credito'>
              <GlobalButton
                style={{ padding: '16px 22px' }}
                dropShadow={false}
                background={colors.light}
                labelColor={colors.primary}
                border={colors.primary}
                hoverColor={colors.lightShade}
                buttonLabel='Tentar novamente'
              />
            </Link>
            <Link to='/'>
              <GlobalButton
                style={{ padding: '16px 22px' }}
                dropShadow={false}
                background={colors.light}
                labelColor={colors.primary}
                border={colors.primary}
                hoverColor={colors.lightShade}
                buttonLabel='Voltar ao Início'
              />
            </Link>

            <GlobalButton
              style={{ padding: '16px 22px' }}
              dropShadow={false}
              background={colors.light}
              labelColor={colors.primary}
              border={colors.primary}
              hoverColor={colors.lightShade}
              buttonLabel='Preciso de ajuda'
              onClick={() => onHelpRequest()}
            />
          </div>
        </React.Fragment>
      )}
    </HandleWrapper>
  )
}

const ReturnPayment = () => {
  let query = new URLSearchParams(useLocation().search)

  const [donationPurchaseData, setDonationPurchaseData] = useAtom(donationData)
  const sessionDonationData = JSON.parse(sessionStorage.getItem('donationData'))

  if (!donationPurchaseData.id && sessionDonationData) {
    setDonationPurchaseData({
      ...sessionDonationData,
    })
  }

  if (!isUserLogged()) {
    return <Redirect to='/login' />
  }

  return (
    <React.Fragment>
      <SEO title='Finalização do Pagamento - Financiamento Coletivo' />
      <Layout>
        <Main>
          {query.get('error') === '1' ? <HandleErrorCase query={query} /> : <HandleSucessCase query={query} />}
        </Main>
      </Layout>
    </React.Fragment>
  )
}

export default ReturnPayment

import React from 'react'
import styled from 'styled-components'

import SEO from '../../components/common/Seo'
import Layout from '../../components/common/Layout'
import { Container, Row, Col } from 'reactstrap'
import UserProfileForm from '../../components/forms/UserProfileForm'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import { Redirect } from 'react-router-dom'
import { useAtom } from 'jotai'
import { userData } from '../../services/state/appState'
const { colors } = theme

const Main = styled.div`
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  padding-top: 60px;

  .page-title {
    font-size: 34px;
    color: ${colors.black};
    padding-bottom: 16px;
  }
  .page-description {
    font-size: 16px;
    color: ${colors.gray};
  }
`
const StyledGrid = styled(Container)`
  ${mixins.mainPageGrid}
  .grid-border-left {
    border-left: 1px solid ${colors.lightShade};
  }
`
const InformationSidepane = styled.div`
  .information-title {
    font-size: 22px;
    font-weight: 700;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
    margin: 30px 0;
  }
  ul li {
    color: ${colors.gray};
    font-size: 15px;
    margin-bottom: 1.6rem;
    line-height: 22px;
  }
`

const UserProfile = () => {
  const [atomUserData] = useAtom(userData)

  if (!atomUserData) {
    return <Redirect to='/' />
  }

  if (atomUserData.toKickstart === true) {
    return <Redirect to='/' />
  }

  return (
    <React.Fragment>
      <SEO title='Editar Conta - Financiamento Coletivo' />
      <Layout>
        <Main>
          <StyledGrid fluid>
            <Row>
              <Col>
                <h1 className='page-title'>Editar perfil</h1>
                <p className='page-description'>
                  Altere as informações que desejar e clique em <b>Salvar Configurações</b> para salvar as alterações do seu
                  perfil.
                </p>

                <React.Fragment>
                  <UserProfileForm />
                </React.Fragment>
              </Col>
              <Col xs='4' className='grid-border-left'>
                <InformationSidepane>
                  <h3 className='information-title'>Informações sobre sua conta:</h3>
                  <ul>
                    <li>
                      O endereço salvo no seu perfil deve ser o seu endereço de residência atual. É através dele que
                      geraremos os boletos e entregaremos suas recompensas de doação.
                    </li>
                    <li>
                      A conta usada neste site é a mesma usada no aplicativo <b>OraçãoPlay</b>. Você pode usá-la para
                      acessar o aplicativo a qualquer momento.
                    </li>
                  </ul>
                </InformationSidepane>
              </Col>
            </Row>
          </StyledGrid>
        </Main>
      </Layout>
    </React.Fragment>
  )
}

export default UserProfile

import React, { useState, useEffect } from 'react'

import { Spinner } from 'reactstrap'
import FaqItems from './FaqItems'
import api from '../../services/api/api'

const FaqItemsContainer = () => {
  const [faqData, setFaqData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api.get('faq').then(res => {
      setTimeout(() => {
        setFaqData(res.data)
        setIsLoading(false)
      }, 100)
    })
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : faqData ? (
        faqData.map(questionItem => (
          <FaqItems
            faqQuestion={questionItem.question}
            faqAnswer={questionItem.answer}
            key={questionItem.question_id}
          />
        ))
      ) : null}
    </React.Fragment>
  )
}

export default FaqItemsContainer

const theme = {
  fonts: {
    RobotoSlab: 'Roboto-Slab, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, serif',
    Merriweather: 'Merriweather, serif'
  },

  colors: {
    // Primary color variants
    primary: 'rgb(121,85,72)',
    primaryShade: 'rgb(106, 75, 63)',
    primaryTint: 'rgb(134, 102, 90)',
    primaryContrast: 'rgb(255, 255, 255)',

    // Secondary color variants
    secondary: 'rgb(197,134,65)',
    secondaryShade: 'rgb(173, 118, 57)',
    secondaryTint: 'rgb(203, 146, 84)',
    secondaryContrast: 'rgb(255, 255, 255)',

    // Tertiary color (only one variant because it's a linear-gradient)
    tertiary: 'linear-gradient(225deg, #895C48 0%, #C58641 100%)',

    // Light color variants
    light: 'rgb(242,242,242)',
    lightShade: 'rgb(230, 230, 230)',
    lightTint: 'rgb(245, 246, 249)',
    lightContrast: 'rgb(0, 0, 0)',

    // Misc colors
    gray: 'rgb(90,90,90)',
    black: 'rgb(33,33,33)',
    background: 'rgb(249, 249, 249)',
    buttonDropShadow: '0px 1px 6px rgba(0, 0, 0, 0.3)',
    cardDropShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
    tabHover: '#e9ecef',

    // Map opacity colors
    mapFillColor0: 'rgba(121,85,72, 0.25)',
    mapFillColor10: 'rgba(121,85,72, 0.28)',
    mapFillColor20: 'rgba(121,85,72, 0.31)',
    mapFillColor30: 'rgba(121,85,72, 0.34)',
    mapFillColor40: 'rgba(121,85,72, 0.37)',
    mapFillColor50: 'rgba(121,85,72, 0.40)',
    mapFillColor60: 'rgba(121,85,72, 0.43)',
    mapFillColor70: 'rgba(121,85,72, 0.46)',
    mapFillColor80: 'rgba(121,85,72, 0.49)',
    mapFillColor90: 'rgba(121,85,72, 0.52)',
    mapFillColor100: 'rgba(121,85,72, 0.55)',

    // Colors for the alert components
    warningBackground: 'rgb(255, 238, 178)',
    warningHighlight: 'rgb(197,134,65)',
    successBackground: 'rgba(0, 204, 136, 0.15)',
    successHighlight: 'rgb(0, 204, 136)',

  }
}

export default theme

import React from 'react'
import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'

import { Nav, NavItem } from 'reactstrap'

import theme from '../../styles/theme'
import mixins from '../../styles/mixins'
import media from '../../styles/media'

import GlobalButton from '../common/GlobalButton'

const { colors } = theme

const TabsWrapper = styled.nav`
  font-size: 18px;
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  z-index: 2;
  top: 0;

  background: ${colors.background};
  border-top: 2px solid ${colors.lightShade};
  border-bottom: 2px solid ${colors.lightShade};

  ${media.phablet`
    font-size: 14px;
  `}

  .tabs-button-container {
    width: 200px;
    align-items: center;
    display: flex;
    ${media.tinyDesktop`
      display: none;
    `}
  }

  ${mixins.tabStyleOverride}
`

const HomeTabs = () => {
  return (
    <React.Fragment>
      <TabsWrapper>
        <Nav tabs>
          <NavItem>
            <NavLink
              isActive={(match, location) => (location.pathname === '/' || location.pathname === '/sobre')}
              to='/sobre' className='nav-link' activeClassName='active nav-link-active'
            >
              Sobre
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='/faq' className='nav-link' activeClassName='active nav-link-active'>
              FAQ
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='/progresso' className='nav-link' activeClassName='active nav-link-active'>
              Progresso
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='/seja-um-construtor' className='nav-link' activeClassName='active nav-link'>
              Seja um construtor
            </NavLink>
          </NavItem>
        </Nav>

        <div className='tabs-button-container'>
          <Link to='/escolher-doacao' style={{ width: '100%' }}>
            <GlobalButton
              dropShadow={true}
              background={colors.tertiary}
              labelColor={colors.light}
              border={colors.tertiary}
              hoverColor={colors.tertiary}
              buttonLabel='QUERO APOIAR'
            />
          </Link>
        </div>
      </TabsWrapper>
    </React.Fragment>
  )
}

export default HomeTabs

import React from 'react'
import styled from 'styled-components'

import mixins from '../../styles/mixins'
import theme from '../../styles/theme'
import media from '../../styles/media'

const { colors } = theme

const Main = styled.header `
  background-color: ${colors.light};
  min-height: 225px;
`
const HeroContainer = styled.div `
  ${mixins.sidePadding}
  ${mixins.desktopAlignCenter}
  text-align: center;
  padding-bottom: 60px;
  padding-top: 60px;
  ${media.tablet`
      padding-bottom: 30px;
      padding-top: 30px;
    `}
`
const HeroTitle = styled.h1`
  font-size: 34px;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 24px;
  color: ${colors.black}; 
`
const HeroDescription = styled.h2`
  font-size: 16px;
  margin: 0 auto;
  max-width: 600px;
  color: ${colors.gray};
`

const Hero = ({heroTitle, heroDescription, background}) => {
  return (
    <Main hasBackground={background}>
      <HeroContainer>
        <HeroTitle>{heroTitle}</HeroTitle>
        <HeroDescription>{heroDescription}</HeroDescription>
      </HeroContainer>
    </Main>
  )
}

export default Hero

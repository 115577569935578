import React, {useState} from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask'
import { useAtom } from 'jotai'
import { eachYearOfInterval, formatISO } from 'date-fns'
import FormikErrorFocus from 'formik-error-focus'
import { cpf as CpfValidator, cnpj as CnpjValidator } from 'cpf-cnpj-validator'

import { donationData } from '../../services/state/donationPurchaseData'
import { getUser } from '../../services/utils/user'
import { validateName } from '../../services/utils/validation'

import GlobalButton from '../common/GlobalButton'

import media from '../../styles/media'
import mixins from '../../styles/mixins'
import theme from '../../styles/theme'

const { colors } = theme

const StyledForm = styled(Form)`
  ${mixins.formStyles}

  .flex-container {
    display: flex;
    justify-content: space-between;
    ${media.tablet` display: block; `}
  }
  .flex-container-item___left {
    margin-top: 6px;
    margin-right: 26px;
    ${media.tablet`
      margin-right: inherit;
      margin-top: 26px; 
    `}
  }
  .flex-container-item___validation-date {
    margin-top: 6px;
    margin-bottom: 12px;
    margin-right: 26px;
    display: flex;
    width: 100%;
    ${media.tablet` 
      margin-right: inherit;
    `}
  }
  .flex-container-item___parcelamento {
    margin-top: 6px;
    margin-bottom: 12px;
  }
  .select-control-container {
    margin-right: 12px;
  }
`

const InstallmentFeeComponent = ({ name, onBlur, value, onChange, recurrency }) => {
  const [donationPurchaseData] = useAtom(donationData)
  const installmentFeeOptions = [
    {
      label: 'Pagar à vista',
      value: 1,
    },
    {
      label: 'Parcelar em 2x',
      value: 2,
    },
    {
      label: 'Parcelar em 3x',
      value: 3,
    },
    {
      label: 'Parcelar em 4x',
      value: 4,
    },
    {
      label: 'Parcelar em 5x',
      value: 5,
    }
  ]

  if (donationPurchaseData.value >= 300) {
    return (
      <React.Fragment>
        <label htmlFor={name}>Parcelamento</label>
        <select name={name} onChange={onChange} onBlur={onBlur} value={value}>
          {recurrency ? (
            <option value={1}>Pagar à vista</option>
          ) : null}

          {!recurrency ? (
            <React.Fragment>
              {installmentFeeOptions.map(option => (
                <option value={option.value}>{option.label}</option>
              ))}
            </React.Fragment>
          ) : null}
        </select>
      </React.Fragment>
    )
  } else {
    return null
  }
}

const CreditCardForm = () => {
  const history = useHistory()
  const [donationPurchaseData, setDonationPurchaseData] = useAtom(donationData)
  const [transactionRecurrency, setTransactionRecurrency] = useState(false)

  const sessionDonationData = sessionStorage.getItem('donationData')

  const paymentMethodData = sessionDonationData ? sessionDonationData.paymentMethodData : false

  const getUserData = function (getUserPropName) {
    if (getUser()[`${getUserPropName}`]) {
      return getUser()[`${getUserPropName}`]
    } else {
      return false
    }
  }

  const getYearFromIso = function (date) {
    return date.substring(0, 4)
  }

  const creditCardYearsInterval = [
    '',
    ...eachYearOfInterval({
      start: new Date(),
      end: new Date(new Date().getFullYear() + 10, 1, 1),
    }),
  ]

  const creditCardMonthsOptions = [
    { label: 'Mês', value: '' },
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
  ]

  const creditCardYearsOptions = creditCardYearsInterval.map(function (date) {
    if (date === '') {
      return {
        label: 'Ano',
        value: '',
      }
    } else {
      return {
        label: getYearFromIso(formatISO(date)),
        value: getYearFromIso(formatISO(date)),
      }
    }
  })

  // Sets the creditCardDonationIsRecurrent based on the target value
  const handlePaymentType = e => {
    let donationRecurrentValue = e.currentTarget.value
    setDonationPurchaseData(false)

    if (donationRecurrentValue === 'recurrent') {
      setDonationPurchaseData({
        ...donationPurchaseData,
        donationIsRecurrent: true,
      })
    }

    if (donationRecurrentValue === 'unique') {
      setDonationPurchaseData({
        ...donationPurchaseData,
        donationIsRecurrent: false,
      })
    }
  }

  return (
    <Formik
      initialValues={{
        nome: paymentMethodData ? paymentMethodData.nome : '',
        numero_cartao: paymentMethodData ? paymentMethodData.numero_cartao : '',
        data_validade_mes: '',
        data_validade_ano: '',
        codigo_seguranca: paymentMethodData ? paymentMethodData.codigo_seguranca : '',
        parcela: 1,
        cpf_cnpj: getUserData('cpf') ? getUserData('cpf') : '',
        doacao_recorrente: 'unique',
      }}
      validationSchema={Yup.object().shape({
        nome: Yup.string().required('Insira o nome que consta no cartão'),

        numero_cartao: Yup.string().required('Insira o número do cartão (somente números, sem espaços)'),

        codigo_seguranca: Yup.number().required('Insira o código de segurança que consta no cartão'),
        data_validade_mes: Yup.number().required('test1'),
        data_validade_ano: Yup.number().required('test1'),

        parcela: Yup.number(),

        cpf_cnpj: Yup.string()
          .required('Insira seu CPF/CNPJ')
          .matches(
            /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/,
            'Insira um CPF/CNPJ válido.'
          )
          .test('cpf-valido', 'Insira um CPF/CNPJ válido', value => {
            return CpfValidator.isValid(value) || CnpjValidator.isValid(value)
          }),

        doacao_recorrente: Yup.string().required('Selecione com que frequência gostaria de doar'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const formattedValues = {
          nome: values.nome,
          numero_cartao: values.numero_cartao ? values.numero_cartao : '',
          data_validade: `${values.data_validade_mes}/${values.data_validade_ano}`,
          codigo_seguranca: values.codigo_seguranca ? values.codigo_seguranca : '',
          parcela: values.parcela ? Number(values.parcela) : false,
          cpf_cnpj: values.cpf_cnpj ? values.cpf_cnpj : '',
        }

        if (values.data_validade_mes && values.data_validade_ano) {
          setDonationPurchaseData({
            ...donationPurchaseData,
            paymentMethodData: formattedValues,
          })
          setSubmitting(false)
          history.push('/pagamento?active-step=delivery-adress')
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div className='form-control-container'>
            <label htmlFor='nome'>Nome (como consta no cartão)</label>
            <Field
              type='text'
              inputMode='text'
              name='nome'
              onChange={handleChange}
              onBlur={handleBlur}
              validate={validateName}
              value={values.nome}
            />
            {errors.nome && touched.nome && <div className='validation-error'>{errors.nome}</div>}
          </div>

          <div className='form-control-container'>
            <label htmlFor='nome'>Número do cartão (somente números)</label>
            <InputMask
              type='tel'
              name='numero_cartao'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.numero_cartao}
              mask='9999 9999 9999 9999'
            />
            {errors.numero_cartao && touched.numero_cartao && (
              <div className='validation-error'>{errors.numero_cartao}</div>
            )}
          </div>

          <label className='expiration-general-label'>Validade</label>
          <div className='flex-container'>
            <div className='form-control-container'>
              <div className='flex-container-item___validation-date'>
                <div className='select-control-container'>
                  <select
                    name='data_validade_mes'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.data_validade_mes}
                  >
                    {creditCardMonthsOptions.map(option => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>

                <div className='select-control-container'>
                  <select
                    name='data_validade_ano'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.data_validade_ano}
                  >
                    {creditCardYearsOptions.map(option => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              </div>

              {(errors.data_validade_mes && touched.data_validade_mes) ||
              (errors.data_validade_ano && touched.data_validade_ano) ? (
                <div className='validation-error'>Insira o mês e ano de validade do cartão</div>
              ) : null}

              <input type='hidden' name='data_validade' value={values.data_validade} />
            </div>

            <div className='form-control-container flex-container-item___left'>
              <label htmlFor='nome'>Código de segurança (CVC)</label>
              <InputMask
                type='tel'
                maxLength={4}
                name='codigo_seguranca'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.codigo_seguranca}
              />
              {errors.codigo_seguranca && touched.codigo_seguranca && (
                <div className='validation-error'>{errors.codigo_seguranca}</div>
              )}
            </div>
            <div className='flex-container-item___parcelamento'>
              <InstallmentFeeComponent
                type='number'
                name='parcela'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.parcela}
                recurrency={transactionRecurrency}
              />
            </div>
          </div>
          <div className='form-control-container'>
            <label htmlFor='nome'>CPF/CNPJ do titular</label>
            <CpfCnpj type='tel' name='cpf_cnpj' onChange={handleChange} onBlur={handleBlur} value={values.cpf_cnpj} />
            {errors.cpf_cnpj && touched.cpf_cnpj && <div className='validation-error'>{errors.cpf_cnpj}</div>}
          </div>

          {donationPurchaseData.isBuilderDonation ? null : (
            <div className='form-control-container'>
              <label htmlFor='doacao_recorrente'>Selecione o tipo de pagamento:</label>
              <select
                value={values.doacao_recorrente}
                name='doacao_recorrente'
                onChange={e => {
                  handleChange(e)
                  let currentRecurrency = e.target.value
                  if (currentRecurrency === 'unique') {
                    setTransactionRecurrency(false)
                  }
                  if (currentRecurrency === 'recurrent') {
                    setTransactionRecurrency(true)
                  }
                  handlePaymentType(e)
                }}
                onBlur={e => {
                  handleBlur(e)
                  handlePaymentType(e)
                }}
                onSubmit={e => {
                  handleSubmit(e)
                  handlePaymentType(e)
                }}
              >
                <option value='unique'>Doação Única</option>
                <option value='recurrent'>Doação Recorrente</option>
              </select>
            </div>
          )}

          <GlobalButton
            large={false}
            dropShadow={true}
            background={colors.primary}
            labelColor={colors.light}
            border={colors.primary}
            hoverColor={colors.primary}
            buttonLabel='Prosseguir com a doação'
            type='submit'
          />

          <FormikErrorFocus offset={0} align={'top'} focusDelay={50} ease={'linear'} duration={500} />
        </StyledForm>
      )}
    </Formik>
  )
}

export default CreditCardForm

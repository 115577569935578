import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Spinner } from 'reactstrap'
import { LiteYoutubeEmbed } from 'react-lite-yt-embed'

import media from '../../styles/media'
import theme from '../../styles/theme'

import HomeDonationChart from '../home/HomeDonationChart'

import api from '../../services/api/api'

const { colors } = theme

const Main = styled.section `
  background: ${colors.light};
  padding-bottom: 64px;
  display: flex;
  justify-content: center;
`

const StyledContainer = styled(Container)`
  background: ${colors.background};
  border: solid 2px ${colors.lightShade};
  width: 100%;
  margin: 0 16px;
  max-width: 1500px;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 405px;
  ${media.tinyDesktop`
    max-height: initial;
    min-height: initial;   
  `}

  .col-4 {
    ${media.tinyDesktop`
      max-width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
    `}
  }
  .row {
    ${media.tinyDesktop`
      display: flex;
      flex-direction: column-reverse;
   `}
  }
  .iframe-wrapper {
    position: relative;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const LoadingContainer = styled.div`
  text-align: center;
  max-height: 520px;
  min-height: 405px;
  ${media.tinyDesktop`
    max-height: initial;
    min-height: initial;   
  `}
`

const MainPageContent = () => {
  const [homepageVideo, setHomepageVideo] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api.get('site-metadata').then(res => {
      setTimeout(() => {
        setHomepageVideo(res.data.homepage_video_URL)
        setIsLoading(false)
      }, 100)
    })
  }, [])

  return (
    <Main>
      {isLoading ? (
        <LoadingContainer>
          <Spinner color='secondary' />
        </LoadingContainer>
      ) : homepageVideo ? (
        <StyledContainer fluid>
          <Row>
            <Col>
              <div className='iframe-wrapper'>
                <LiteYoutubeEmbed
                  id={homepageVideo}
                  params='controls=0&modestbranding=2&rel=0&enablejsapi=1'
                  defaultPlay={false}
                  isMobile={false}
                  title='Does this provide an alt?'
                />
              </div>
            </Col>
            <Col xs='4'>
              <HomeDonationChart />
            </Col>
          </Row>
        </StyledContainer>
      ) : null}
    </Main>
  )
}

export default MainPageContent

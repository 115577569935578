import React, { useEffect, useState } from 'react'
import { useAtom } from 'jotai'

import { donationData } from '../../services/state/donationPurchaseData'

import { Spinner } from 'reactstrap'
import PaymentMethodItem from './PaymentMethodItem'
import api from '../../services/api/api'

const PaymentMethodItemsContainer = () => {
  const [donationPurchaseData] = useAtom(donationData)
  const [paymentMethod, setPaymentMethod] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api.get(`payment-method?value=${donationPurchaseData.value}`).then(res => {
      setTimeout(() => {
        setPaymentMethod(res.data)
        setIsLoading(false)
      }, 100)
    }).catch(() => {})
  }, [donationPurchaseData.value])

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ textAlign: 'center', margin: '36px', height: '100vh' }}>
          <Spinner color='secondary' />
        </div>
      ) : paymentMethod ? (
        paymentMethod.map(method => (
          <PaymentMethodItem
            paymentMethodTitle={method.payment_method_title}
            paymentMethodDescription={method.payment_method_description}
            paymentMethodOptions={method.has_payment_method_options}
            paymentMethodId={method.id}
            key={method.id}
          />
        ))
      ) : null}
    </React.Fragment>
  )
}

export default PaymentMethodItemsContainer
